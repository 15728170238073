<template>
  <v-dialog
    content-class="cross__dialog dialog-order"
    max-width="max-content"
    v-model="visibility"
    scrollable
  >
    <v-card :loading="loadingLogs" min-height="400">
      <div class="cross__inner">
        <v-btn fab small  elevation="0" @click="$emit('close')">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </div>
      <v-card-title>Детальна інформація</v-card-title>
      <v-card-text>
        <v-data-table
          :headers="logDetailsHeader"
          :items="details"
          :item-key="'uuid'"
          hide-default-footer
          class="my-table supply crmForms__listDialog-table"
          locale="uk"
          dense
          loader-height="1"
          :items-per-page="details.length"
          :no-data-text="$t('table.noData')"
        >
          <template v-slot:item.status="{ item }">
            <v-chip label class="text-align-center" x-small :color="getStatusColor(item.status)" dark>
              <span>{{ item.status }}</span>
            </v-chip>
          </template>
        </v-data-table>
        <!-- <v-card
          class="mb-3"
          elevation="1"
          v-for="(detail, index) in details"
          :key="index"
        >
          <v-card-text
            ><v-row no-gutters>
              <div class="font-weight-bold" :class="`color-${detail.status}`">
                {{ detail.status }}
              </div>
            </v-row>
            <v-row no-gutters>{{ detail.message }}</v-row>
            <v-row no-gutters
              >Status message: {{ detail.status_message }}</v-row
            >
            <v-row no-gutters>Time: {{ detail.time }}</v-row>
            <v-row no-gutters>Duration: {{ detail.duration }}</v-row>
          </v-card-text>
        </v-card> -->
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import modulesService from "@/services/request/modules/modulesService";
import notifications from "@/mixins/notifications";

export default {
  mixins: [notifications],
  props: {
    visible: {
      require: true,
    },
    log_uuid: {
      require: true,
    },
  },
  data: () => ({
    details: [],
    loadingLogs: false,
    logDetailsHeader:[]
  }),
  mounted() {
    this.renderLogDetailsTable();
    this.getDetails();
  },
  methods: {
    renderLogDetailsTable() {
      this.logDetailsHeader = [
        {
          text: "Status",
          align: "center",
          sortable: false,
          value: "status",
        },
        {
          text: "Message",
          align: "left",
          sortable: false,
          value: "message",
        },
        {
          text: "Status Message",
          align: "left",
          sortable: false,
          value: "status_message",
        },
        {
          text: "Time",
          align: "left",
          sortable: false,
          value: "time",
        },
        {
          text: "Duration",
          align: "left",
          sortable: false,
          value: "duration",
        },
      ];
    },
    async getDetails() {
      this.loadingLogs = true;
      try {
        this.details = await modulesService.getLogDetails(this.log_uuid);
        this.loadingLogs = false;
      } catch (e) {
        console.log(e)
      }
    },
    getStatusColor(status) {
      // Повертає колір відповідно до значення статусу
      if (status === "SUCCESS") {
        return "light-green";
      } else if (status === "WARNING") {
        return "light-orange";
      } else if (status === "ERROR") {
        return "red";
      } else {
        return "light-grey";
      }
    },
  },
  computed: {
    visibility: {
      get() {
        return this.visible;
      },
      set() {
        this.$emit("close");
      },
    },
  },
};
</script>

<style scoped lang="scss">


.status-text {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  text-align: center;
}

</style>
