<template>
  <VRow no-gutters align="center" justify="space-between">
    <VCol cols="12" :class="$vuetify.breakpoint.xs ? 'mx-auto' : ''">
      <VCard class="mb-1 pt-2 pb-1">
        <supply-tables v-if="!$vuetify.breakpoint.xs" :type="type" />
        <div v-else>
          <supply-filter
            :filterPropsExact="filterProps"
            :states="states"
            @onApplyFilter="onApplyFilter"
          />
          <supply-tables :type="type" />
        </div>
      </VCard>
    </VCol>
  </VRow>
</template>

<script>
import EventBus from "../../events/EventBus";
import loader from "../../mixins/loader";
import notifications from "../../mixins/notifications";
import user from "../../mixins/user";
import SizeUi from "../../mixins/SizeUi";
import SupplyFilter from "./sm/SupplyFilter.vue";
import StatusCircle from "@/components/move-list/StatusCircle.vue";
import NsDataTable from "@/components/UI/ns-data-table/ns-data-table.vue";
import supplyMixin from "@/components/supply/supplyMixin";
import SupplyTables from "@/components/supply/SupplyTables.vue";

export default {
  name: "SupplyRowList",
  components: {
    SupplyTables,
    NsDataTable,
    SupplyFilter,
    StatusCircle
  },
  mixins: [loader, user, notifications, SizeUi, supplyMixin],
  data: () => ({
    isLoadingFilter: false,
    activeItem: null,
    page: 1,
    perPageList: [5, 10, 15],
    intervalFetchData: null,
    firstNumber: "",
    secondNumber: "",
    total: 12,
    items: [],
    loadMoreDisabled: false,
    totalPages: 0,
    loading: false,
    windowHeight: document.documentElement.clientHeight,
  }),
  async mounted() {
    EventBus.$on("supply-in-modified", async (result) => {
      this.$emit("changeMetrics");
      if(!this.permissions.client){
        result = null // to do unique uuid key param
      }
      EventBus.$emit("on-modified", result);
    });
    EventBus.$on("supply-out-modified", async (result) => {
      this.$emit("changeMetrics");
      if(!this.permissions.client){
        result = null // to do unique uuid key param
      }
      EventBus.$emit("on-modified", result );
    });
  },
  beforeDestroy() {
    EventBus.$off("supply-out-modified");
    EventBus.$off("supply-in-modified");
  },
  watch: {
    filterProps: {
      deep: true,
      handler() {
        this.$scrollTop({}, document.querySelector(".v-data-table__wrapper"));
      }
    }
  },
  props: {
    type: {
      required: true
    },
    filter: String
  }
};
</script>

<style lang="scss">
.blocked_wrapper {
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  border-radius: 50%;
}
</style>
