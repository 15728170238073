<template>
  <VDialog
    max-width="1050px"
    content-class="cross__dialog"
    :transition="$dialogTransition"
    v-model="visibility"
  >
    <VCard class="px-1 py-1">
      <div class="cross__inner">
        <VBtn fab small elevation="0" @click="onClose()">
          <VIcon>mdi-close</VIcon>
        </VBtn>
      </div>
      <div class="text-center" v-if="loading">
        <v-progress-linear
          :size="50"
          color="primary"
          indeterminate
          absolute
          bottom
        ></v-progress-linear>
      </div>
      <v-simple-table class="my-table" height="75vh" fixed-header>
        <template v-slot:default>
          <thead>
            <tr>
              <th class="text-left font-weight-bold ">
                <div class="pb-1 d-flex align-center">
                  <VSelect
                    dense
                    :items="items"
                    :item-text="getStatusText"
                    :item-value="'id_state'"
                    hide-details
                    solo
                    @change="onScanerField({ id_state: filter.state })"
                    :placeholder="$t('supply.states_map.state')"
                    v-model="filter.state"
                    clearable
                    class="text-caption"
                  >
                    <template v-slot:item="{ item }">
                      <div class="d-flex align-center justify-content-start ">
                        <StatusCircle :status-name="item.id_state" :type="'supply'" class="pb-1" />
                        <div class="elispsis">
                          {{ getStatusText(item) }}
                        </div>
                      </div>
                    </template>
                    <template v-slot:selection="{ item }">
                      <div class="d-flex align-center justify-content-start ">
                        <StatusCircle :status-name="item.id_state" :type="'supply'" class="pb-1" />
                        <div class="elispsis">
                          {{ getStatusText(item) }}
                        </div>
                      </div>
                    </template>
                  </VSelect>
                </div>
              </th>
              <th class="text-left font-weight-bold ">
                <div class="pb-1 d-flex align-center">
                  <VSelect
                    dense
                    :items="items"
                    :item-text="getStatusTextNext"
                    :item-value="'id_state_next'"
                    hide-details
                    solo
                    @change="onScanerField({ id_state_next: filter.next_state })"
                    :placeholder="$t('supply.states_map.next_state')"
                    v-model="filter.next_state"
                    clearable
                    class="text-caption"
                  >
                    <template v-slot:item="{ item }">
                      <div class="d-flex align-center justify-content-start ">
                        <StatusCircle
                          :status-name="item.id_state_next"
                          :type="'supply'"
                          class="pb-1"
                        />
                        <div class="elispsis">
                          {{ getStatusTextNext(item) }}
                        </div>
                      </div>
                    </template>
                    <template v-slot:selection="{ item }">
                      <div class="d-flex align-center justify-content-start ">
                        <StatusCircle
                          :status-name="item.id_state_next"
                          :type="'supply'"
                          class="pb-1"
                        />
                        <div class="elispsis">
                          {{ getStatusTextNext(item) }}
                        </div>
                      </div>
                    </template>
                  </VSelect>
                </div>
              </th>
              <th class="text-left font-weight-bold ">
                <div class="pb-1 d-flex align-center">
                  <VSelect
                    dense
                    :items="items"
                    :item-text="userType"
                    :item-value="'lvl_access'"
                    hide-details
                    solo
                    @change="onScanerField({ lvl_access: filter.lvl_access })"
                    :placeholder="$t('supply.states_map.lvl_access')"
                    v-model="filter.lvl_access"
                    clearable
                    class="text-caption"
                  >
                    <template v-slot:item="{ item }">
                      {{ userType(item.lvl_access) }}
                    </template>
                    <template v-slot:selection="{ item }">
                      {{ userType(item.lvl_access) }}
                    </template>
                  </VSelect>
                </div>
              </th>
              <th class="font-weight-bold ">
                <div class="pb-1 d-flex align-center text-center">
                  <VTextField
                    dense
                    :placeholder="$t('table.description')"
                    clearable
                    hide-details
                    solo
                    disabled
                  />
                </div>
              </th>
            </tr>
          </thead>
          <tbody name="fade" is="transition-group">
            <tr v-for="(item, index) in items" :key="index">
              <td>
                <div class="d-flex align-center justify-content-start ">
                  <StatusCircle
                    :status-name="item.id_state"
                    :type="'supply'"
                    :class="{ sm: !isLarge }"
                  />
                  <div class="elispsis text-subtitle-2">
                    {{ item?.[`${currentLanguage.key}_name`] || item?.name || "" }}
                  </div>
                </div>
              </td>
              <td>
                <div class="d-flex align-center justify-content-start ">
                  <StatusCircle
                    :status-name="item.id_state_next"
                    :type="'supply'"
                    :class="{ sm: !isLarge }"
                  />
                  <div class="elispsis text-subtitle-2">
                    {{ item?.[`next_${currentLanguage.key}_name`] || item?.next_name || "" }}
                  </div>
                </div>
              </td>
              <td class="text-left">
                {{ userType(item.lvl_access) }}
              </td>
              <td class="text-center">
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <VIcon v-bind="attrs" v-on="on" size="22">mdi-help-circle-outline</VIcon>
                  </template>
                  <span>{{ item.description }}</span>
                </v-tooltip>
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </VCard>
  </VDialog>
</template>

<script>
import supplyService from "@/services/request/supply/supplyService.js";
import SizeUi from "@/mixins/SizeUi";
import StatusCircle from "@/components/move-list/StatusCircle.vue";
import language from "@/mixins/language";
import { mapGetters } from "vuex";
import _ from "lodash";

export default {
  name: "statesMapDialog",
  mixins: [language, SizeUi],
  props: {
    visible: {
      required: true,
      type: Boolean
    }
  },
  components: {
    StatusCircle
  },
  data: () => ({
    loading: false,
    items: [],
    filter: {
      state: "",
      next_state: "",
      lvl_access: ""
    }
  }),
  mounted() {
    this.getItems();
  },
  methods: {
    onClose() {
      this.visibility = false;
    },
    async getItems(params = {}) {
      try {
        this.loading = true;
        const newItems = await supplyService.getStatesList(params);
        this.items = newItems;
        this.loading = false;
      } catch (e) {
        console.log(e);
        this.loading = false;
      }
    },
    userType(item) {
      switch (item) {
        case "1":
          return this.currentLanguage.key == "uk" ? "Власник" : "Owner";
        case "10":
          return this.currentLanguage.key == "uk" ? "Адміністратор" : "Admin";
        case "20":
          return this.currentLanguage.key == "uk" ? "Менеджер" : "Manager";
        case "30":
          return this.currentLanguage.key == "uk" ? "Працівник" : "Employee";
        case "40":
          return this.currentLanguage.key == "uk" ? "Кліент" : "Client";
        case "50":
          return "CRM";
        default:
          return "Public";
      }
    },
    getStatusText(item) {
      if (!item) {
        return null;
      } else {
        if (
          item[this.currentLanguage.key + "_name"] !== undefined &&
          item[this.currentLanguage.key + "_name"] !== null
        ) {
          return item[this.currentLanguage.key + "_name"];
        } else if (item.name !== undefined && item.name !== null) {
          return item.name;
        } else {
          return "";
        }
      }
    },
    getStatusTextNext(item) {
      if (!item) {
        return null;
      } else {
        if (
          item["next_" + this.currentLanguage.key + "_name"] !== undefined &&
          item["next_" + this.currentLanguage.key + "_name"] !== null
        ) {
          return item["next_" + this.currentLanguage.key + "_name"];
        } else if (item.name !== undefined && item.name !== null) {
          return item.next_name;
        } else {
          return "";
        }
      }
    },
    onScanerField: _.debounce(function(params) {
      this.getItems(params);
    }, 100)
  },
  computed: {
    visibility: {
      get() {
        return this.visible;
      },
      set() {
        return this.$emit("close");
      }
    },
    ...mapGetters(["currentLanguage"])
  }
};
</script>
