<template>
  <div>
    <v-tooltip bottom>
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          class="btn-edit br-10"
          text
          outlined
          v-bind="attrs"
          v-on="on"
          @click="
            visible = !visible;
            typeDialog = 0;
          "
        >
          <v-icon color="success" plain>
            mdi-import
          </v-icon>
        </v-btn>
      </template>
      <span>{{ $t('supply.import_supply') }}</span>
    </v-tooltip>
    <VDialog
    :transition="$dialogTransition"
      v-model="visible"
      max-width="800px"
      content-class="cross__dialog dialog-order"
      scrollable
    >
      <VCard :height="typeDialog === 0 ? '500' : '450'" :loading="loading" :disabled="loading">
        <div class="cross__inner">
          <v-btn fab small  elevation="0" @click="onClose">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </div>
        <VCardTitle>
          <VRow class="mr-0">
            <VCol cols="12" md="6" class="crmForms__listDialog__header-title">
              <v-btn icon @click="onCancelCreate" v-if="typeDialog !== 0">
                <v-icon>
                  mdi-arrow-left
                </v-icon>
              </v-btn>
              <span>
               {{ $t("form.modules") }}
              </span>
            </VCol>
            <VCol cols="12" md="6" class="crmForms__listDialog__header-nav text-right" v-if="typeDialog === 0">
              <v-btn
                class="ml-2 btn-settingBoard success-bg text-white text-transform-none br-10"
                :class="{ 'w-100': $vuetify.breakpoint.mobile }"
                @click="addNewModuleList"
              >
                <v-icon :left="$vuetify.breakpoint.mobile">mdi-plus</v-icon>
                {{ $vuetify.breakpoint.mobile ? $t("btn.create") : "" }}
              </v-btn>
            </VCol>
          </VRow>
        </VCardTitle>
        <VCardText>
          <VTabsItems v-model="typeDialog">
            <VTabItem>
              <v-data-table
                :headers="modulesHeader"
                :items.sync="modulesList"
                item-key="email"
                hide-default-footer
                class="my-table supply crmForms__listDialog-table"
                locale="uk"
                dense
                loader-height="1"
                :items-per-page="modulesList.length"
                :no-data-text="$t('table.noData')"
              >
                <!-- eslint-disable-next-line  -->
                <template v-slot:item.btn="{ item }">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        v-bind="attrs"
                        v-on="on"
                        class="btn-edit sm mr-3"
                        color="primary"
                        dense
                        small
                        @click="
                      selectBoard(item.uuid)
                      suitsVisible = true;
                    "
                      >
                        <v-icon>mdi-sitemap-outline</v-icon>
                      </v-btn>
                    </template>
                    <span>{{ $t('supply.kit_supply') }}</span>
                  </v-tooltip>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        v-bind="attrs"
                        v-on="on"
                        class="btn-edit sm mr-3"
                        color="primary"
                        dense
                        small
                        @click="showLogs(item)"
                      >
                        <v-icon dense>
                          mdi-information
                        </v-icon>
                      </v-btn>
                    </template>
                    <span>{{ $t('supply.info') }}</span>
                  </v-tooltip>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        v-bind="attrs"
                        v-on="on"
                        class="btn-edit sm mr-3"
                        color="primary"
                        dense
                        small
                        @click="showSettings(item)"
                      >
                        <v-icon dense>
                          mdi-cog
                        </v-icon>
                      </v-btn>
                    </template>
                    <span>{{ $t('supply.settings') }}</span>
                  </v-tooltip>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        v-bind="attrs"
                        v-on="on"
                        class="btn-edit sm mr-3"
                        color="primary"
                        dense
                        small
                        @click="
                      selectedItem = item;
                      uninstallDialog = true;
                    "
                      >
                        <v-icon dense>
                          mdi-trash-can
                        </v-icon>
                      </v-btn>
                    </template>
                    <span>{{ $t('supply.import_remove') }}</span>
                  </v-tooltip>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        v-bind="attrs"
                        v-on="on"
                        @dblclick.prevent="
                    selectedItem = item;
                    installDialog = true;"
                        class="btn-edit sm mr-3"
                        color="primary"
                        dense
                        small
                        @click="
                      selectedItem = item;
                      installDialog = true;
                    "
                      >
                        <v-icon dense>
                          mdi-play
                        </v-icon>
                      </v-btn>
                    </template>
                    <span>{{ $t('supply.import_run') }}</span>
                  </v-tooltip>
                </template>
                <!-- eslint-disable-next-line  -->
                <template v-slot:item.switch="{item}">
                  <v-switch
                    :ripple="false"
                    v-model="item.is_auto_run"
                    hide-details
                    color="primary"
                    false-value="0"
                    true-value="1"
                    @change="onChangeAutoRun(item)"
                  ></v-switch>
                </template>
                <!-- eslint-disable-next-line  -->
                <template v-slot:item.name="props">
                  <v-edit-dialog
                    :return-value.sync="props.item.name"
                    @save="onEditModuleName(props.item)"
                  >
                    {{ props.item.name || "--" }}
                    <template v-slot:input>
                      <v-text-field
                        v-model="props.item.name"
                        label="Edit"
                        single-line
                        counter
                        :rules="[rules.number, props.item.type]"
                      ></v-text-field>
                    </template>
                  </v-edit-dialog>
                </template>

                <!-- eslint-disable-next-line  -->
                <template v-slot:item.uuid="{ item }">
                  <span class=" copyTxt" @click="CopyToClipboard(item.uuid)">
                    {{ item.uuid }}
                  </span>
                </template>

                <!-- eslint-disable-next-line  -->
              </v-data-table>
            </VTabItem>
            <VTabItem>
              <VDivider v-if="!$vuetify.breakpoint.mobile"/>
              <v-data-table
                ref="myTable"
                :headers="storeModulesHeader"
                :items="storeModulesList"
                item-key="email"
                hide-default-footer
                class="my-table supply crmForms__listDialog-table"
                locale="uk"
                dense
                loader-height="1"
                :items-per-page="storeModulesList.length"
                :no-data-text="$t('table.noData')"
              >
                <!-- eslint-disable-next-line  -->
                <template v-slot:item.btn="{ item }">
                  <v-edit-dialog ref="myEditDialog">
                    <v-btn class="btn-edit sm mr-3" color="success" dense small>
                      <v-icon>mdi-plus</v-icon>
                    </v-btn>
                    <template v-slot:input>
                      <VCard elevation="0">
                        <VCardText>
                          <div>
                            <v-text-field
                              class="mb-5"
                              dense
                              v-model="newModule.name"
                              :maxLength="15"
                              color="success"
                              clearable
                              :label="$t('form.title')"
                              type="text"
                              hide-details="auto"
                            ></v-text-field>
                            <v-text-field
                              class="mb-5"
                              dense
                              v-model="newModule.description"
                              :maxLength="15"
                              color="success"
                              clearable
                              :label="$t('form.description')"
                              type="text"
                              hide-details="auto"
                            ></v-text-field>
                          </div>
                          <v-btn
                            class="btn-edit sm mb-2"
                            color="success"
                            dense
                            small
                            :disabled="loading"
                            @click="addNewModule(item);"
                            style="position: absolute; bottom: 0; right: 0;"
                          >
                            <v-icon dense>
                              mdi-check
                            </v-icon>
                          </v-btn>
                        </VCardText>

                      </VCard>
                    </template>
                  </v-edit-dialog>
                  </template>
                <!-- eslint-disable-next-line  -->
                <template v-slot:item.name="{ item }">
                  <div>
                    {{ item.name }}
                  </div>
                </template>
                <!-- eslint-disable-next-line  -->
                <template v-slot:item.uuid="{ item }">
                  <span class=" copyTxt" @click="CopyToClipboard(item.uuid)">
                    {{ item.uuid }}
                  </span>
                </template>
                <v-data-table
                  :headers="storeModulesHeader"
                  :items.sync="storeModulesList"
                  item-key="email"
                  hide-default-footer
                  class="my-table supply crmForms__listDialog-table"
                  locale="uk"
                  dense
                  loader-height="1"
                  :items-per-page="storeModulesList.length"
                  :no-data-text="$t('table.noData')"
                >
                  <!-- eslint-disable-next-line  -->
                  <template v-slot:item.name="{ item }">
                    <div>
                      {{ item.name }}
                    </div>
                  </template>

                  <!-- eslint-disable-next-line  -->
                  <template v-slot:item.sku="{ item }">
                    <span>
                      {{ item.sku ? item.sku : "--" }}
                    </span>
                  </template>

                  <!-- eslint-disable-next-line  -->
                  <template v-slot:item.uuid="{ item }">
                    <span class=" copyTxt" @click="CopyToClipboard(item.uuid)">
                      {{ item.uuid }}
                    </span>
                  </template>

                  <!-- eslint-disable-next-line  -->
                </v-data-table>
                <!-- eslint-disable-next-line  -->
              </v-data-table>
            </VTabItem>
            <VTabItem>
              <VDivider v-if="!$vuetify.breakpoint.mobile"/>
              <v-data-table
                :headers="settingsHeader"
                :items="SettingTableList"
                item-key="email"
                hide-default-footer
                class="my-table supply crmForms__listDialog-table"
                locale="uk"
                dense
                loader-height="1"
                :items-per-page="SettingTableList.length"
                :no-data-text="$t('table.noData')"
              >
                <!-- eslint-disable-next-line  -->
                <template v-slot:item.btn="{ item }">
                  <v-btn
                    class="btn-edit sm mr-3"
                    color="success"
                    dense
                    small
                    @click="addNewModule(item)">
                    <v-icon dense>
                      mdi-pencil
                    </v-icon>
                  </v-btn>
                </template>
                <!-- eslint-disable-next-line  -->
                <template v-slot:title.text="{ title }">
                  <div>
                    {{ title.text }}
                  </div>
                </template>
                <!-- eslint-disable-next-line  -->
                <template v-slot:item.value="props">
                  <div v-if="props.item.type === 'STRING'">
                    <v-edit-dialog
                      :return-value.sync="props.item.value"
                      @save="changeSettingValue(props.item)"
                      @cancel="cancelChangeSettingValue"
                    >
                      {{ props.item.value || "--" }}
                      <template v-slot:input>
                        <v-text-field
                          v-model="props.item.value"
                          label="Edit"
                          single-line
                          counter
                        ></v-text-field>
                      </template>
                    </v-edit-dialog>
                  </div>
                  <div v-else>
                    <v-switch
                      :ripple="false"
                      @change="changeSettingValue(props.item)"
                      color="green"
                      false-value="0"
                      true-value="1"
                      v-model="props.item.value"
                      hide-details="auto">
                    </v-switch>
                  </div>
                </template>
              </v-data-table>
            </VTabItem>
            <VTabItem>
              <VDivider v-if="!$vuetify.breakpoint.mobile"/>
              <v-data-table
                :headers="logHeader"
                :items="logTableList"
                item-key="email"
                hide-default-footer
                disable-pagination
                :items-per-page="-1"
                class="my-table supply crmForms__listDialog-table"
                locale="uk"
                dense
                :no-data-text="$t('table.noData')"
                @dblclick:row="viewDetails"
              >
                <!-- eslint-disable-next-line  -->
                <template v-slot:item.btn="{ item }">
                  <v-btn
                    class="btn-edit sm"
                    color="primary"
                    dense
                    small
                    @click="
                      logsDetails.selectedItem = item.uuid_run;
                      logsDetails.visible = true;">
                    <v-icon dense>
                      mdi-information
                    </v-icon>
                  </v-btn>
                </template>
                <template v-slot:footer>
                  <v-progress-linear v-if="isLoadingLog" indeterminate color="primary"></v-progress-linear>
                  <div
                    v-if="!logsPagination.isLoadDisabled && !isLoadingLog"
                    v-observer="{ nextPage: nextPageLogs }"
                  />
                </template>
              </v-data-table>
            </VTabItem>
          </VTabsItems>
        </VCardText>
        <VCardActions>
          <VContainer>
            <VRow>
              <!-- <VCol cols="12" v-if="typeDialog === 0">
                <v-btn class="w-100 br-10 text-transform-none" dark @click="onClose">
                  {{ $t("btn.close") }}
                </v-btn>
              </VCol> -->
              <!-- <VCol cols="12" v-if="typeDialog !== 0">
                <v-btn class="w-100 br-10 text-transform-none" dark @click="onCancelCreate">
                  {{ $t("btn.cancel") }}
                </v-btn>
              </VCol> -->
            </VRow>
          </VContainer>
        </VCardActions>
      </VCard>
    </VDialog>
    <suits-component
      :boardUuid="selectedUuidSuits"
      :states="0"
      v-if="suitsVisible"
      :visible="suitsVisible"
      @close="suitsVisible = false"
      :uuid_board="selectedUuidSuits"
      :isAllowStatisticReview="true"
      :isAllowEditSuits="true"
    />
    <logs-details-dialog
      v-if="logsDetails.visible"
      :visible="logsDetails.visible"
      :log_uuid="logsDetails.selectedItem"
      @close="logsDetails.visible = false"
    />
    <ConfirmDialog
      :visible="installDialog"
      @close="installDialog = false"
      @onConfirm="runModule(selectedItem)"
      @onCancel="installDialog = false"
      :typeAlert="'info'"
    >
      <template v-slot:header>
        {{ $t("confirmDialog.startModule.title") }}
      </template>
      <template v-slot:description>
        {{ $t("confirmDialog.startModule.desc") }}
      </template>
    </ConfirmDialog>
    <ConfirmDialog
      :visible="uninstallDialog"
      @close="uninstallDialog = false"
      @onConfirm="unInstallModule(selectedItem)"
      @onCancel="uninstallDialog = false"
      :typeAlert="'error'"
    >
      <template v-slot:header>
        {{ $t("confirmDialog.stopModule.title") }}
      </template>
      <template v-slot:description>
        {{ $t("confirmDialog.stopModule.desc") }}
      </template>
    </ConfirmDialog>
    <!-- <SuitUpdateDialog
        v-if="editStoreModuleConfig.visible"
        :visible="editStoreModuleConfig.visible"
        :uuid_suit="editStoreModuleConfig.uuid_suit"
        @close="onCloseEditSuit"
        :isAllowEditSuits="isAllowEditSuits"
      /> -->
  </div>
</template>

<script>
import notifications from "@/mixins/notifications";
import {validationMixin} from "vuelidate";
import {required} from "vuelidate/lib/validators";
import EventBus from "@/events/EventBus";
import modulesService from "@/services/request/modules/modulesService";
//   import SuitUpdateDialog from "./editSuit/SuitUpdateDialog.vue";
import SuitsComponent from "@/components/crm/suits/SuitsComponent.vue";
import logsDetailsDialog from "./components/logsDetailsDialog.vue";
import ConfirmDialog from "@/components/dialog/ConfirmDialog.vue";
import _ from "lodash";

export default {
  props: {},
  data: () => ({
    rules: {
      number: (value,type) => {
        if(type === "INT"){
          const pattern = /^\d+5/;
          return pattern.test(value) || 'Invalid type'
        }
      },
    },
    isLoadingLog: false,
    page: 1,
    perPage: 10,
    logsPagination:{
      page: 1,
      perPage: 20,
      isLoadDisabled:false,
    },
    loading:false,
    moduleNameMenu: false,
    visible: false,
    typeDialog: 0,
    titles: [],
    storeModulesList: [],
    storeModulesHeader: [],
    settingsHeader: [],
    SettingTableList: [],
    suitsVisible: false,
    selectedUuidSuits: null,
    selectedItem: null,
    installDialog: false,
    uninstallDialog: false,
    modulesList: [],
    modulesHeader: [],
    logHeader: [],
    logTableList: [],
    newModule: {
      name: "",
      description: ""
    },
    newSuit: {
      name: "",
      description: "",
      sku: ""
    },
    logsDetails: {
      visible: false,
      selectedItem: null
    },
    editStoreModuleConfig: {
      visible: false,
      uuid_suit: null
    },
    ModuleConfig: {
      visible: false,
      uuid: null
    }
  }),
  watch: {
    visible() {
      if (this.visible) {
        this.rendeModulesTable();
        this.getModulesList();
      }
    }
  },
  beforeDestroy(){
    EventBus.$off("suit-list-modified");
    
  },
  mounted() {
    EventBus.$on("suit-list-modified", () => {
      this.renderStoreModulesTable();
      this.getStoreModulesList();
    });
  },
  methods: {
    async onHandleSuitListModified() {
      await this.renderSuitsTable();
      await this.getSuitsList();
    },
    selectBoard(value) {
      this.selectedUuidSuits = value;
    },
    nextPageLogs() {
      this.logsPagination.page += 1;

      this.getLogs(true)
    },
    // eslint-disable-next-line
    async onChangeAutoRun({ is_auto_run, uuid }) {
      try {
        this.loading = true
        const formData = new FormData();
        formData.append("is_auto_run", is_auto_run);
        await modulesService.updateAutoRun(uuid, formData);
        this.loading = false

      } catch (e) {
        this.loading = false
        console.log(e)
      }
    },
    CopyToClipboard(data) {
      navigator.clipboard.writeText(data);
      this.setSuccessNotification(this.$t("profile.copied_to_clipboard"));
    },
    showLogs({uuid}) {
      this.ModuleConfig = {
        uuid: uuid,
        visible: true
      };
      try {
        this.typeDialog = 3;
        this.renderLogTable();
        this.getLogs()
      } catch (e) {
        console.log(e)
      } finally {
        this.ModuleConfig = {
          uuid: uuid,
          visible: false
        };
      }
    },
    async getLogs(nextPage = false) {

      try {
        this.isLoadingLog = true;
        const params = {
          offset: !nextPage ? 0 : (this.logsPagination.page - 1) * this.logsPagination.perPage,
          limit: this.logsPagination.perPage
        }
        const res = await modulesService.getLogs(this.ModuleConfig.uuid, params);



        if (nextPage) {
          this.logTableList.push(...res);
        } else {
          this.logTableList = res;
        }


        this.logsPagination.isLoadDisabled = res.length < this.logsPagination.perPage;


        this.isLoadingLog = false;
      } catch (e) {
        console.log(e)
      }
    },
    async changeSettingValue({
                               uuid,
                               value,
                               name
                             }) {
      try {
        await modulesService.editSetting(this.ModuleConfig.uuid, value, name);
      } catch (e) {
        console.log(e)
      }
    },
    cancelChangeSettingValue() {

    },
    async showSettings({uuid}) {
      this.ModuleConfig = {
        uuid: uuid,
        visible: true
      };
      try {
        const res = await modulesService.getSettingsList(uuid);
        this.typeDialog = 2;
        this.renderSettingTableHeader();
        this.titles = [...res]
        this.titles.forEach(title => {
          if (title.name === "lp_send_on_create" || title.name === "crm_send_on_create") {
            title.text = this.$t('form.send_on_create')
          } else if (title.name === "lp_process_status" || title.name === "crm_process_status"){
            title.text = this.$t('form.process_status')
          } else if (title.name === "lp_key" || title.name === "crm_key"){
            title.text = this.$t('form.key')
          } else if(title.name === "lp_name" || title.name === "crm_name"){
            title.text = this.$t('form.title')
          }
        });
        this.SettingTableList = res;
      } catch (e) {
        console.log(e)
      } finally {
        this.ModuleConfig = {
          uuid: uuid,
          visible: false
        };
      }
    },
    async runModule({uuid}) {
      this.ModuleConfig = {
        uuid: uuid,
        visible: true
      };
      this.installDialog = false;
      this.loading = true;
      try {

        const res = await modulesService.runModule(uuid);
        if (res) {
          this.setSuccessNotification(res.message);
        }
        this.modulesList = [];
        this.getModulesList();
      } catch (e) {
        console.log(e)
      } finally {
        this.ModuleConfig = {
          uuid: uuid,
          visible: false
        };

        this.loading = false;
      }
    },
    async unInstallModule({uuid}) {
      this.ModuleConfig = {
        uuid: uuid,
        visible: true
      };
      this.loading = true;
      this.uninstallDialog = false;
      try {
        await modulesService.unInstallModule(uuid);
        this.modulesList = [];
        this.getModulesList();
      } catch (e) {
        console.log(e)
      } finally {
        this.ModuleConfig = {
          uuid: uuid,
          visible: false
        };
        this.loading = false;
      }
    },
    async addNewModule({
                         uuid,
                         name
                       }) {
      this.ModuleConfig = {
        uuid: uuid,
        visible: true
      };
      try {
        this.loading = true
        await modulesService.installModule(uuid, {
          name: this.newModule.name,
          description: this.newModule.description
        });
        this.modulesList = [];
        this.getModulesList();
        this.typeDialog = 0;
        this.moduleNameMenu = false;
      } catch (e) {
        console.log(e)
      } finally {
        this.loading = false
        this.$refs.myTable.$el.click()
        this.newModule = { name: "", description: "" };
        this.ModuleConfig = {
          uuid: uuid,
          visible: false
        };
      }
    },
    async onEditModuleName({
                             uuid,
                             name
                           }) {
      this.ModuleConfig = {
        uuid: uuid,
        visible: true
      };
      try {
        const formData = new FormData();
        formData.append("name", name);
        await modulesService.editModule(uuid, formData);
        this.storeModulesList = [];
        this.getStoreModulesList();
        this.typeDialog = 0;
      } catch (e) {
        console.log(e)
      } finally {
        this.ModuleConfig = {
          uuid: uuid,
          visible: false
        };
      }
    },
    addNewModuleList() {
      this.typeDialog = 1;
      this.renderStoreModulesTable();
      this.getStoreModulesList();
    },
    renderStoreModulesTable() {
      this.storeModulesHeader = [
        {
          text: this.$t("form.title"),
          align: "left",
          sortable: false,
          value: "name"
        },
        {
          text: this.$t("form.description"),
          align: "left",
          sortable: false,
          value: "description"
        },
        {
          text: "uuid",
          align: "left",
          sortable: false,
          value: "uuid"
        }
      ];
      this.storeModulesHeader.push({
        text: this.$t("form.control"),
        align: "center",
        sortable: false,
        value: "btn",
        width: "10%"
      });
    },
    renderSettingTableHeader() {
      this.settingsHeader = [
        {
          text: this.$t("form.heading"),
          align: "left",
          sortable: false,
          value: "text"
        },
        {
          text: this.$t("form.value"),
          align: "left",
          sortable: false,
          value: "value"
        }
      ];
      this.storeModulesHeader.push({
        text: this.$t("form.control"),
        align: "center",
        sortable: false,
        value: "btn",
        width: "1%"
      });
    },
    renderLogTable() {
      this.logHeader = [
        {
          text: this.$t("form.total_duration"),
          align: "left",
          sortable: false,
          value: "total_duration"
        },
        {
          text: this.$t("form.start_run_time"),
          align: "left",
          sortable: true,
          value: "start_run_time"
        },
        {
          text: this.$t("form.end_run_time"),
          align: "left",
          sortable: true,
          value: "end_run_time"
        },
        {
          text: this.$t("form.control"),
          align: "center",
          sortable: false,
          value: "btn",
          width: "10%"
        }
      ];
      this.storeModulesHeader.push({
        text: this.$t("form.control"),
        align: "center",
        sortable: false,
        value: "btn",
        width: "10%"
      });
    },
    rendeModulesTable() {
      this.modulesHeader = [
        {
          text: this.$t("form.title"),
          align: "left",
          sortable: false,
          value: "name"
        },
        {
          text: this.$t("form.description"),
          align: "left",
          sortable: false,
          value: "description"
        }
      ];

      this.modulesHeader.push(
        {
          text: this.$t("form.control"),
          align: "center",
          sortable: false,
          value: "btn",
          width: "35%"
        },
        {
          text: this.$t("supply.auto"),
          align: "center",
          sortable: false,
          value: "switch",
          width: "10%"
        }
      )

    },
    async getStoreModulesList() {
      try {
        const res = await modulesService.getStoreModulesList();
        this.storeModulesList = res;

      } catch (e) {
        console.log(e)
      }
    },
    async getModulesList() {
      try {
        const res = await modulesService.getModulesList();
        this.modulesList = res.reverse(); // fix to sort by date

      } catch (e) {
        console.log(e)
      }
    },
    onClose() {
      this.onCancelCreate();
      this.visible = false;
    },
    viewDetails(e, item) {
      this.logsDetails.selectedItem = item.item.uuid_run;
      this.logsDetails.visible = true;
    },
    onCloseEditSuit() {
      this.editStoreModuleConfig.visible = false;
      this.editStoreModuleConfig.uuid_suit = null;
    },
    onCancelCreate() {
      this.typeDialog = 0;
    },
    editSuit({uuid}) {
      this.editStoreModuleConfig.visible = true;
      this.editStoreModuleConfig.uuid_suit = uuid;
    },
    async onSave() {
      try {
        //
      } catch (e) {
        console.log(e)
      }
    },
    async onDeleteSuit({uuid}) {
      try {
        // await crmSuits.deleteSuit(uuid);
        this.getStoreModulesList();
      } catch (e) {
        console.log(e)
      }
    }
  },
  computed: {
    nameEditErrors() {
      const errors = [];
      if (!this.$v.newSuit.name.$dirty) {
        return errors;
      }
      // eslint-disable-next-line no-unused-expressions
      !this.$v.newSuit.name.required && errors.push(this.$t("form.errors.TitleRequierd"));
      return errors;
    }
  },
  validations: {
    newSuit: {
      name: {required}
    }
  },
  components: {
    //   SuitUpdateDialog
    SuitsComponent,
    logsDetailsDialog,
    ConfirmDialog
  },
  mixins: [validationMixin, notifications]
};
</script>
