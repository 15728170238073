<template>
  <div ref="scrollWrappedElement">
    <div class="px-0 py-0 mt-2 position-relative">
      <VRow
        no-gutters
        v-if="$vuetify.breakpoint.smAndDown"
        :justify="permissions.can_search ? 'space-between' : 'end'"
        class="mb-3"
      >
        <VCol
          v-if="permissions.can_search"
          :cols="$vuetify.breakpoint.sm ? '5' : 'auto' || $vuetify.breakpoint.xs ? '6' : 'auto'"
          class="mr-4"
        >
          <v-autocomplete
            dense
            prepend-inner-icon="mdi-warehouse"
            :items="Object.values(depots)"
            :item-value="'uuid'"
            :item-text="'name'"
            hide-details
            v-model="chosenDeports"
            solo
            class="br-10"
            :menu-props="{ bottom: true, offsetY: true }"
            :no-data-text="$t('table.noData')"
            :label="$t('form.storage')"
            small-chips
            deletable-chips
          />
        </VCol>
        <VCol cols="auto">
          <v-tooltip bottom v-if="permissions.can_see_states_map">
            <template v-slot:activator="{ on, attrs }">
              <VBtn
                depressed
                @click="statesMapDialog = true"
                text
                class="btn-edit br-10 mx-1"
                outlined
                v-bind="attrs"
                v-on="on"
              >
                <VIcon color="success">mdi-table-key</VIcon>
              </VBtn>
            </template>
            <span>{{ $t("supply.states_map.states_table") }}</span>
          </v-tooltip>
        </VCol
        >
        <VCol cols="auto" class="mr-1">
          <ex-modules-dialog v-if="permissions.can_do_import"/>
        </VCol>
        <VCol cols="auto" class="text-left">
          <VBtn
            depressed
            class="success-bg text-white text-transform-none br-10"
            @click="addSupplyInDialog = true"
            v-if="activeTab !== 1 && activeTab !== 2"
          >
            <VIcon left>mdi-plus</VIcon>
            <div v-if="!$vuetify.breakpoint.xs || !permissions.can_search">
              {{ $t("btn.create") }}
            </div>
            <package-down-icon fill="white" width="22" height="22" view-box-value="0 0 12 24"/>
          </VBtn>

          <VBtn
            depressed
            class="success-bg text-white text-transform-none br-10"
            @click="addSupplyInDialog = true"
            v-if="activeTab !== 0 && activeTab !== 2"
            disabled
          >
            <VIcon left>mdi-plus</VIcon>
            {{ $t("btn.create") }}
            <return-icon fill="white" width="18" height="18" view-box-value="0 0 24 24"/>
          </VBtn>

          <VBtn
            depressed
            :disabled="permissions.manager || permissions.owner"
            class="success-bg text-white text-transform-none br-10"
            @click="itemDialog.visible = true"
            v-if="activeTab !== 0 && activeTab !== 1"
          >
            <VIcon left>mdi-plus</VIcon>
            <div v-if="!$vuetify.breakpoint.xs || !permissions.can_search">
              {{ $t("btn.create") }}
            </div>
            <package-up-icon fill="white" width="22" height="22" view-box-value="0 0 12 24"/>
          </VBtn>
        </VCol>
      </VRow>
      <VRow
        no-gutters
        class="align-center justify-space-between pa-0 "
        style="border-bottom: 1px solid rgba(0, 0, 0, 0.12)"
        :style="$vuetify.breakpoint.mdAndDown ? 'border-top: 1px solid rgba(0, 0, 0, 0.12);' : ''"
      >
        <VRow
          no-gutters
          align="center"
          justify="center"
          justify-md="space-between"
          justify-lg="space-between"
          justify-sm="space-between"
        >
          <VCol class="pa-0" cols="auto">
            <VTabs
              show-arrows
              active-class="active-tab-class"
              background-color="transparent"
              slider-color="green"
              v-model="activeTab"
              @change="onChangeTab"
              class="supply-component"
              color="success"
            >
              <VTab :style="$vuetify.breakpoint.xs ? 'max-width:95px' : 'min-width:190px'">
                <package-down-icon class="mr-1"/>
                {{ $t("supply.income_goods") }}
              </VTab>
              <VTab :style="$vuetify.breakpoint.xs ? 'max-width:120px' : 'min-width:190px'">
                <return-icon width="18" height="18" view-box-value="0 0 24 24" class="mr-1"/>
                {{ $t("supply.returned_shipped") }}
              </VTab>
              <VTab :style="$vuetify.breakpoint.xs ? 'max-width:100px' : 'min-width:190px'">
                <package-up-icon class="mr-1"/>
                {{ $t("supply.shipped") }}
              </VTab>
            </VTabs>
          </VCol>
          <VRow justify="end" align="center" no-gutters v-if="!$vuetify.breakpoint.smAndDown">
            <VCol :cols="$vuetify.breakpoint.xs ? '5' : 'auto'" class="mr-4">
              <v-autocomplete
                v-if="permissions.can_search"
                dense
                prepend-inner-icon="mdi-warehouse"
                :items="Object.values(depots)"
                :item-value="'uuid'"
                :item-text="'name'"
                hide-details
                v-model="chosenDeports"
                solo
                class="br-10"
                :menu-props="{ bottom: true, offsetY: true }"
                :no-data-text="$t('table.noData')"
                :label="$t('form.storage')"
                small-chips
                deletable-chips
              />
            </VCol>
            <VCol cols="auto" class="text-left">
              <VRow no-gutters v-if="activeTab !== 1 && activeTab !== 2">
                <v-tooltip bottom v-if="permissions.can_see_states_map">
                  <template v-slot:activator="{ on, attrs }">
                    <VBtn
                      depressed
                      @click="statesMapDialog = true"
                      text
                      class="btn-edit br-10"
                      outlined
                      v-bind="attrs"
                      v-on="on"
                    >
                      <VIcon color="success">mdi-table-key</VIcon>
                    </VBtn>
                  </template>
                  <span>{{ $t("supply.states_map.states_table") }}</span>
                </v-tooltip>
                <VBtn
                  depressed
                  class="success-bg text-white text-transform-none br-10 mx-1"
                  @click="addSupplyInDialog = true"
                  v-if="activeTab !== 1 && activeTab !== 2"
                >
                  <VIcon left>mdi-plus</VIcon>
                  {{ $t("btn.create") }}
                  <package-down-icon fill="white" width="22" height="22"
                                     view-box-value="0 0 12 24"/>
                </VBtn>
              </VRow>
              <VRow no-gutters v-if="activeTab !== 0 && activeTab !== 2">
                <v-tooltip bottom v-if="permissions.can_see_states_map">
                  <template v-slot:activator="{ on, attrs }">
                    <VBtn
                      depressed
                      @click="statesMapDialog = true"
                      text
                      class="btn-edit br-10 mx-1"
                      outlined
                      v-bind="attrs"
                      v-on="on"
                    >
                      <VIcon color="success">mdi-table-key</VIcon>
                    </VBtn>
                  </template>
                  <span>{{ $t("supply.states_map.states_table") }}</span>
                </v-tooltip>
                <VBtn
                  depressed
                  class="success-bg text-white text-transform-none br-10"
                  @click="addSupplyInDialog = true"
                  v-if="activeTab !== 0 && activeTab !== 2"
                  disabled
                >
                  <VIcon left>mdi-plus</VIcon>
                  {{ $t("btn.create") }}
                  <return-icon fill="white" width="18" height="18" view-box-value="0 0 24 24"/>
                </VBtn>
              </VRow>
              <VRow no-gutters v-if="activeTab !== 0 && activeTab !== 1">
                <v-tooltip bottom v-if="permissions.can_see_states_map">
                  <template v-slot:activator="{ on, attrs }">
                    <VBtn
                      depressed
                      @click="statesMapDialog = true"
                      text
                      class="btn-edit br-10 mx-1"
                      outlined
                      v-bind="attrs"
                      v-on="on"
                    >
                      <VIcon color="success">mdi-table-key</VIcon>
                    </VBtn>
                  </template>
                  <span>{{ $t("supply.states_map.states_table") }}</span>
                </v-tooltip>
                <ex-modules-dialog v-if="permissions.can_do_import"></ex-modules-dialog>
                <VBtn
                  depressed
                  :disabled="permissions.manager || permissions.owner"
                  class="success-bg text-white text-transform-none br-10 mx-1"
                  @click="itemDialog.visible = true"
                  v-if="activeTab !== 0 && activeTab !== 1"
                >
                  <VIcon left>mdi-plus</VIcon>
                  {{ $t("btn.create") }}
                  <package-up-icon fill="white" width="22" height="22" view-box-value="0 0 12 24"/>
                </VBtn>
              </VRow>
            </VCol>
          </VRow>
        </VRow>
      </VRow>
      <VTabsItems v-model="activeTab">
        <VTabItem>
          <SupplyRowList @changeMetrics="getMetrics('in')" type="in" :filter="chosenDeports"
                         @add-customer="addClientDialog = true"/>
          <VRow align="center" no-gutters
                style="font-size: 13px !important; border-top:1px solid #e4e4e4;">
            <VCol cols="auto" class="mt-2 px-2" style="border-right:2px solid #e4e4e4">
              За день: {{ metricsIn.new }}
            </VCol>
            <VCol cols="auto" class="mt-2 px-2" style="border-right:2px solid #e4e4e4">
              Повернення: {{ metricsIn.return }}
            </VCol>
            <VCol cols="auto" class="mt-2 px-2"
                  style="margin-left: auto; border-left:2px solid #e4e4e4">
              Загалом: {{ totalMessageIn }}
            </VCol>
          </VRow>
        </VTabItem>
        <VTabItem>
          <SupplyRowList type="returned"
                         :filter="chosenDeports"
                         />
          <VRow align="center" no-gutters
                style="font-size: 13px !important; border-top:1px solid #e4e4e4;">
            <VCol cols="auto" class="mt-2 px-2"
                  style="margin-left: auto; border-left:2px solid #e4e4e4">
              Загалом: {{ totalMessageReturn }}
            </VCol>
          </VRow>
        </VTabItem>
        <VTabItem>
          <SupplyRowList @changeMetrics="getMetrics('out')" type="out"
                          :filter="chosenDeports"/>
          <VRow align="center" no-gutters
                style="font-size: 13px !important; border-top:1px solid #e4e4e4;">
            <VCol cols="auto" class="mt-2 px-2" style="border-right:2px solid #e4e4e4">
              За день: {{ metricsOut.new }}
            </VCol>
            <VCol cols="auto" class="mt-2 px-2" style="border-right:2px solid #e4e4e4">
              Відправлені: {{ metricsOut.sent }}
            </VCol>
            <VCol cols="auto" class="mt-2 px-2" style="border-right:2px solid #e4e4e4">
              В очікуванні: {{ metricsOut.wait }}
            </VCol>
            <VCol cols="auto" class="mt-2 px-2"
                  style="margin-left: auto; border-left:2px solid #e4e4e4">
              Загалом: {{ totalMessageOut }}
            </VCol>
          </VRow>
        </VTabItem>
      </VTabsItems>
      <AddSupplyDialog
        :visible="createDialog"
        :addresses="addresses"
        @close="createDialog = false"
        :products="products"
      />
      <AddSupplyInDialog
        v-if="addSupplyInDialog"
        :visible="addSupplyInDialog"
        @close="addSupplyInDialog = false"
        @add-customer="addClientDialog = true"
      />
      <DialogWrapper
        :visible="itemDialog.visible"
        @close="itemDialog.visible = false"
        settingDialog="supply"
        :typeDialog="itemDialog.type"
        v-if="itemDialog.visible"
      >
        <OrderDialog :typeDialog="itemDialog.type" settingDialog="supply"/>
      </DialogWrapper>
      <AddClientDialog :visible="addClientDialog" @close="addClientDialog = false"/>
      <statesMapDialog v-if="permissions.can_see_states_map && statesMapDialog" :visible="statesMapDialog" @close="statesMapDialog = false"/>
    </div>
  </div>
</template>

<script>
import notifications from "../../mixins/notifications";
import loader from "../../mixins/loader";
import AddSupplyDialog from "./AddSupplyDialog.vue";
import OrderDialog from "../orderDialog/OrderDialog.vue";
import AddSupplyInDialog from "./AddSupplyInDialog.vue";
import AddClientDialog from "../users/AddClientDialog.vue";
import SupplyRowList from "./SupplyRowList.vue";
import user from "../../mixins/user";
import depotService from "../../services/request/depot/depotService";
import DialogWrapper from "../orderDialog/DialogWrapper.vue";
import exModulesDialog from "./exModules/exModulesDialog.vue";
import metricsService from '@/services/request/metrics/metricsService';
import statesMapDialog from '@/components/supply/dialogs/statesMapDialog.vue';
import {mapActions, mapGetters} from "vuex";
import * as actionTypes from "@/store/modules/deportOwnerSupplyKey/types/actions";
import * as getterTypes from "@/store/modules/deportOwnerSupplyKey/types/getters";
import * as getterType from "@/store/modules/totalValues/types/getters";

export default {
  name: "SupplyComponent",
  mixins: [notifications, loader, user],
  data: () => ({
    itemDialog: {
      visible: false,
      type: "add",
      item: {},
      user_states: []
    },
    createDialog: false,
    addresses: [],
    products: [],
    depots: [],
    chosenDeports: "",
    searchLoading: false,
    activeTab: 3,
    addSupplyInDialog: false,
    addSupplyOutDialog: false,
    addClientDialog: false,
    statesMapDialog: false,
    metricsIn: {},
    metricsOut: {},
  }),
  components: {
    AddSupplyDialog,
    AddSupplyInDialog,
    AddClientDialog,
    SupplyRowList,
    OrderDialog,
    DialogWrapper,
    exModulesDialog,
    statesMapDialog
  },
  mounted() {
    this.setDeportKey(null);
    this.getMetrics();
    const activeTabData = localStorage.getItem("supply-page.activeTab");
    this.activeTab = activeTabData ? Number(activeTabData) : 3;
    this.onMounted();
  },
  methods: {
    ...mapActions("deportKey", {
      setDeportKey: actionTypes.SET_DEPORT_KEY
    }),
    async onMounted() {
      try {
        this.setLoading(true);
        if (this.permissions.can_create_depot) {
          this.depots = await depotService.getDepotsList();
        }
        await this.getSupplyItems();
        this.setLoading(false);
      } catch (e) {
        console.log(e);
        this.setLoading(false);
      }
    },
    onChangeTab(tabNumber) {
      localStorage.setItem("supply-page.activeTab", Number(tabNumber));
    },
    async getSupplyItems(params = {}) {
      try {
        // eslint-disable-next-line no-param-reassign
        params.offset = 0;
        // eslint-disable-next-line no-param-reassign
        params.limit = 5;
        this.setLoading(false);
      } catch (e) {
        this.searchLoading = false;
        this.setLoading(false);
        console.log(e);
      }
    },
    async getMetrics(param = 'all') {
      try {
        const date = new Date().toISOString();
        const params = {
          year: date.substr(0, 4),
          month: date.substr(5, 2),
          day: date.substr(8, 2)
        }
        if (param == 'all' || param == 'in') {
          const resIn = await metricsService.getMetricsDeliveriesIn(params);
          this.metricsIn = resIn;
        }
        if (param == 'all' || param == 'out') {
          const resOut = await metricsService.getMetricsDeliveriesOut(params);
          this.metricsOut = resOut;
        }
      } catch (e) {
        console.log(e);
      }
    },
  },
  computed: {
    ...mapGetters("deportKey", {
      deportKey: getterTypes.DeportKey
    }),
    ...mapGetters('totalValues', {
      totalMessageOut: getterType.GET_TOTAL_OUT,
    }),
    ...mapGetters('totalValues', {
      totalMessageIn: getterType.GET_TOTAL_IN,
    }),
    ...mapGetters('totalValues', {
      totalMessageReturn: getterType.GET_TOTAL_RETURN,
    }),
    visibility: {
      get() {
        return this.visible;
      },
      set() {
        this.$emit("close");
      }
    }
  },
  watch: {
    chosenDeports: {
      handler(e) {
        this.setDeportKey(e);
      }
    }
  },
};
</script>

<style scoped lang="scss">
body,
html {
  overflow: hidden;
}

.active-tab-class svg {
  fill: green;
}

.theme--light.v-tabs-items {
  background-color: transparent;
}

.theme--light.v-tabs > .v-tabs-bar {
  background-color: transparent;
}

.supply-tab-counter {
  background: #eeeeee;
  border-radius: 50%;
}

.active-tab-class {
  color: var(--v-green-base) !important;

  i {
    color: var(--v-green-base);
  }
}

.v-slide-group__wrapper .v-tabs-slider-wrapper .v-tabs-slider {
  background: var(--v-green-base) !important;
}
</style>
