// eslint-disable-next-line import/no-cycle
import requestService from "../requestService";

const prefix = "/modules";
export default {

  async getStoreModulesList() {
    const resposne = await requestService.get(`${prefix}/store/list`);
    return resposne?.data?.object;
  },
  async getModulesList() {
    const resposne = await requestService.get(`${prefix}/list`);
    return resposne?.data?.object;
  },
  async installModule(uuid,payload) {
    const resposne = await requestService.get(`${prefix}/install/${uuid}`,payload);
    return resposne?.data?.object;
  },
  async editModule(uuid,payload) {
    const resposne = await requestService.post(`${prefix}/update/${uuid}`,payload);
    return resposne?.data?.object;
  },
  async runModule(uuid) {
    const resposne = await requestService.post(`${prefix}/run/${uuid}`);
    return resposne?.data;
  },
  async getLogs(uuid, params) {
    const resposne = await requestService.get(`${prefix}/log/list/${uuid}`, params);
    return resposne?.data?.object;
  },
  async unInstallModule(uuid) {
    const resposne = await requestService.get(`${prefix}/uninstall/${uuid}`);
    return resposne?.data?.object;
  },
  async getSettingsList(uuid) {
    const resposne = await requestService.get(`/settings/list/${uuid}`);
    return resposne?.data?.object;
  },
  async editSetting(uuid,value,settingName) {
    const resposne = await requestService.put(`/settings/set/${uuid}/${settingName}?value=${value}`);
    return resposne?.data?.object;
  },
  async getLogDetails(uuid) {
    const resposne = await requestService.get(`${prefix}/log/list/details/${uuid}`);
    return resposne?.data?.object;
  },
  async updateAutoRun(uuid, payload) {
    const resposne = await requestService.post(`${prefix}/update/${uuid}`,payload);
    return resposne?.data?.object;
  }
};
