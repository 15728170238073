import {mapGetters} from "vuex";
import supplyService from "@/services/request/supply/supplyService";
import {
  validationForCities,
  validationForName,
  validationForNumbers,
  validationForPhoneNumber,
  validationForSurname
} from "@/mixins/helpers";
import _ from "lodash";
import depotService from "@/services/request/depot/depotService";
import loader from "../../mixins/loader";
import * as getterTypes from "@/store/modules/deportOwnerSupplyKey/types/getters";

export default {
  mixins: [loader],
  data: () => ({
    params: {},
    filterProps: {
      number: "",
      ttn: "",
      cell_details__ns_code: "",
      time_created: "",
      date_created_begin: "",
      date_created_end: "",
      state: "",
      name: "",
      surname: "",
      phone: "",
      delivery_address_details__name_city: ""
    },
    states: [],
    dateMenu: false,
    dateMenuPeriod: false,
    time_created_period: [],
    dateRangeText: '',
    supplyItem: {},
    totalMessageOut: '',
    totalMessageIn: '',
    totalMessageReturn: '',
  }),
  methods: {
    validationForCities,
    validationForPhoneNumber,
    validationForSurname,
    validationForName,
    validationForNumbers,
    async updateList() {
        const prefix = this.permissions.client ? "delivery_address_details__" : "user_details__";
      console.log(this.filterProps)
        const params = {};
        Object.keys(this.filterProps)
          .forEach(key => {
            if (this.filterProps[key]) {
              params.uselike = true;
              if (key === "state") {
                params.id_state = this.filterProps.state;
              } else if (key === "name" || key === "surname" || key === "phone") {
                params[prefix + key] = this.filterProps[key];
              } else {
                params[key] = this.filterProps[key];
              }
            }
          });
        if (this.deportKey) {
          params.uuid_deport = this.deportKey;
        }
        if (this.type !== 'returned') {
          params.type = this.type;
        }
        if (params.user_details__phone) {
          params.user_details__phone = params.user_details__phone.replace(/[^+\d]/g, "");
        }
        if (params.delivery_address_details__phone) {
          params.delivery_address_details__phone = params.delivery_address_details__phone.replace(/[^+\d]/g, "");
        }
        this.params = params;
        console.log(this.params)
        this.setLoading(false);
    },
    async checkStates() {
      try {
        this.setLoading(true);
        if (this.type === "out") {
          this.states = await depotService.getAllStates({
            type: "DELIVERY_OUT"
          });
        }
        if (this.type === "in") {
          this.states = await depotService.getAllStates({
            type: "DELIVERY_IN"
          });
        }
        if (this.type === "returned") {
          this.states = await depotService.getAllStates({
            type: "DELIVERY_IN"
          });
        }
        if (this.type === "all") {
          const statesIn = await depotService.getAllStates({
            type: "DELIVERY_IN"
          });
          const statesOut = await depotService.getAllStates({
            type: "DELIVERY_OUT"
          });
          this.states = statesIn.concat(statesOut);
        }
        this.setLoading(false);
      } catch (e) {
        this.setLoading(false);
        console.log(e);
      }
    },
    onApplyFilter(filterItems) {
      this.filterProps = {
        ...filterItems
      };
      this.updateList();
    },
    onChangeDatePeriod() {
      if (this.time_created_period.length === 2) {
        if (this.time_created_period[0] === this.time_created_period[1]) {
          this.filterProps.time_created = this.time_created_period[0];
          this.dateRangeText = this.time_created_period[0];
          this.time_created_period.splice(1, 1);
        } else {
          this.filterProps.time_created = "";
          const firstDate = new Date(this.time_created_period[0]);
          const secondDate = new Date(this.time_created_period[1]);
          if (firstDate > secondDate) {
            this.filterProps.date_created_begin = this.time_created_period[1];
            this.filterProps.date_created_end = this.time_created_period[0];
            this.dateRangeText = `${this.time_created_period[1]} ~ ${this.time_created_period[0]}`;
          } else {
            this.filterProps.date_created_begin = this.time_created_period[0];
            this.filterProps.date_created_end = this.time_created_period[1];
            this.dateRangeText = this.time_created_period.join(' ~ ');
          }
        }
        this.onApplyFilter(this.filterProps);
      } else if (this.time_created_period.length === 1) {
        this.filterProps.date_created_begin = "";
        this.filterProps.date_created_end = "";
        this.dateRangeText = this.time_created_period[0];
        this.filterProps.time_created = this.time_created_period[0]
        this.onApplyFilter(this.filterProps);
      }
    },
    onCancelInterval() {
      this.time_created_period = [];
      this.dateRangeText = '';
      this.filterProps.date_created_begin = "";
      this.filterProps.date_created_end = "";
      this.dateRangeText = "";
      this.filterProps.time_created = "";
      this.onApplyFilter(this.filterProps);
    },
    getStatusText(item) {
      if (this.currentLanguage.key === "en") {
        return item.name;
      }
      return item[`${this.currentLanguage.key}_name`];
    },
    onScanerField: _.debounce(function () {
      this.updateList();
    }, 500),
    async copyToClipboard(e) {
      try {
        await navigator.clipboard.writeText(e);
        this.setSuccessNotification(this.$t("profile.copied_to_clipboard"));
      } catch (e) {
        console.log(e);
      }
    },
  },
  watch: {
    deportKey: {
      handler() {
        this.updateList();
      }
    },
  },
  computed: {
    ...mapGetters(["currentLanguage"]),
    ...mapGetters("deportKey", {
      deportKey: getterTypes.DeportKey
    }),
    // eslint-disable-next-line vue/return-in-computed-property
    getRequestService() {
      if(this.type === 'out') {
        return supplyService.getSupplyListOut
      } else if(this.type === 'in') {
        return supplyService.getSupplyListIn
      } else if(this.type === 'returned') {
        return supplyService.getSupplyListReturned
      }
    },
    supplyHeader() {
      const result =  [
        { text: '#', value: 'order', sortable: false },
        {
          text: "",
          value: "btn",
          align: "center",
          sortable: false
        },
        {
          text: "№",
          value: "reference_number",
          align: "left",
          sortable: false
        },
        {
          text: this.$t('table.ttn'),
          value: "ttn",
          align: "center",
          sortable: false
        },
        {
          text: this.$t('table.name'),
          value: "first_name",
          align: "left",
          sortable: false
        },
        {
          text: this.$t('table.surname'),
          value: "last_name",
          align: "left",
          sortable: false
        },
        {
          text: this.$t('table.phone'),
          value: "phone_number",
          align: "left",
          sortable: false
        },
        {
          text: this.$t('table.delivery_status'),
          value: "delivery_state",
          align: "left",
          sortable: false
        },
        {
          text: this.$t('form.time_created'),
          value: "time_created",
          align: "left",
          sortable: false
        },
      ];
      if (this.permissions.client) {
        result.splice(7, 0,{
          text: this.$t('table.city'),
          value: "city",
          align: "left",
          sortable: false
        })
      }
      if (!this.permissions.client) {
        result.splice(4, 0,{
          text: this.$t('menu.ns_codes'),
          value: "ns_code",
          align: "left",
          sortable: false
        })
      }
      return result;
    },
  },
};
